@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  font-family: 'Open Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
}
html {
  height: 100%;
  padding: 10px;
  overflow-x: hidden;
  font-family: 'Open Sans';
  background-image: linear-gradient(to top,#1d1e20, rgba(0, 162, 170, .5));
}
input, button, select, textarea, optgroup, option {
  font-family: inherit;
}
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
#root {
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 6px;
  background: #fff;
  padding: 24px;
}
.unread {
  background: rgba(4, 59, 199, 0.2);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
